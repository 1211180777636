<template>
  <div :class="$store.state.isCollapse ? 'menu-collapse' : ''" class="menu">
    <div class="menu-logo">
      <router-link :to="{ name: 'console' }" class="logo">
        <img
          class="icon"
          src="https://api.qk.zhongheinfo.com/attachment/default/logo/20200527/5ece2f4a595dd.png"
        />
        <h1>潜客通</h1>
      </router-link>
    </div>
    <div class="menu-list">
      <el-menu
        :collapse="isCollapse"
        :default-active="$route.path"
        :router="true"
        background-color="#002140"
        unique-opened
      >
        <div :key="index" v-for="(item, index) in $store.state.menu">
          <el-menu-item
            :index="'/' + item.route"
            v-if="item.children == undefined"
            @click.native="clearKey"
          >
            <i :class="item.icon"></i>
            <span slot="title">{{ item.name }}</span>
          </el-menu-item>

          <el-submenu
            :index="'/' + item.route"
            popper-class="jue-sub-menu"
            v-else
          >
            <template slot="title">
              <i :class="item.icon"></i>
              <span slot="title">{{ item.name }}</span>
            </template>

            <div :key="index2" v-for="(item2, index2) in item.children">
              <el-menu-item
                @click.native="clearKey"
                :index="'/' + item.route + '/' + item2.route"
                v-if="isMain(item2.menu_id)"
              >
                <i :class="item2.icon"></i>
                <span>{{ item2.name }}</span>
              </el-menu-item>
            </div>
          </el-submenu>
        </div>
      </el-menu>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isCollapse: this.$store.state.isCollapse,
      currentMenu: '', //当前默认菜单
      logo: 'https://ae01.alicdn.com/kf/Hc2c1e5ccfc8644a59d763679a90a6e4be.png',
      title: '4S店潜客管理系统',
      menuData: {},
    }
  },
  watch: {
    $route () {
      this.getCurrentMenu()
    },
    '$store.state.isCollapse': function () {
      this.isCollapse = this.$store.state.isCollapse
    },
  },
  mounted () {
    this.$axios.post('/index/site').then((res) => {
      if (res.data.code == 200) {
        if (res.data.data.logo != '') {
          this.logo = res.data.data.logo
          this.title = res.data.data.name
        }
      }
    })
  },
  methods: {
    isMain (menu_id) {
      if (localStorage.getItem('x-auth-site') != 'default' && (menu_id == 170 || menu_id == 190)) {
        return false
      } else {
        return true
      }
    },
    getCurrentMenu () {
      //获取菜单
      this.$axios.post('/user/menu').then((res) => {
        if (res.data.code == 200) {
          this.menuData = res.data.data
        }
      })

      this.currentMenu = this.$route.path
    },
    clearKey () {
      localStorage.removeItem('searchKey')
    }
  },
}
</script>
<style lang="less" scoped>
.menu {
  width: 250px;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  min-height: 100vh;
  height: 100%;
  background: #002140;
  position: relative;
  z-index: 999;
  &.menu-collapse {
    width: 80px;
    .menu-logo .logo {
      justify-content: center;
    }
    .menu-logo .logo h1 {
      display: none;
    }
  }
}
.menu-logo {
  .logo {
    height: 64px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    // justify-content: center;
    img {
      width: 32px;
      height: 32px;
    }
    svg {
      width: 32px;
      height: 32px;
    }
    h1 {
      font-size: 16px;
      display: inline;
      color: #fff;
      margin-left: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
<style lang="less">
.el-menu {
  border: none;
  .el-submenu__title,
  .el-menu-item {
    color: rgba(255, 255, 255, 0.65) !important;
    height: 40px;
    line-height: 40px;
    margin: 8px 0;
    i {
      color: rgba(255, 255, 255, 0.65) !important;
    }
    &:hover {
      color: #fff !important;
      background: none !important;
      i {
        color: #fff !important;
      }
    }
  }

  .el-menu-item.is-active {
    background: #1890ff !important;
    color: #fff !important;
  }
}
.el-menu--inline {
  background: #000c17 !important;
  .el-menu-item {
    background: #000c17 !important;
  }
}
.el-menu--collapse {
  width: 80px;
  transition: all 0.2s linear;
  .el-submenu__title,
  .el-tooltip {
    text-align: center;
  }

  .el-submenu.is-active .el-submenu__title i {
    color: #fff !important;
  }
}
</style>