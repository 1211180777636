import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/Layout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'console',
    component: Layout,
    redirect: { name: 'dashboard' },
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../views/Dashboard.vue')
      },
      {
        path: '/database/menu',
        name: 'database-menu',
        component: () => import('../views/database/Menu.vue')
      },
      {
        path: '/database/config',
        name: 'database-config',
        component: () => import('../views/database/Config.vue')
      },
      {
        path: '/company/group',
        name: 'company-group',
        component: () => import('../views/company/Group.vue')
      },
      {
        path: '/database/cartype',
        name: 'database-cartype',
        component: () => import('../views/database/CarType.vue')
      },
      {
        path: '/database/result',
        name: 'database-result',
        component: () => import('../views/database/Result.vue')
      },
      {
        path: '/database/custom',
        name: 'database-custom',
        component: () => import('../views/database/Custom.vue')
      },
      {
        path: '/database/reviewer',
        name: 'database-reviewer',
        component: () => import('../views/database/Reviewer.vue')
      },
      {
        path: '/database/kpi',
        name: 'database-kpi',
        component: () => import('../views/database/Kpi.vue')
      },
      {
        path: '/database/subsite',
        name: 'subsite',
        component: () => import('../views/database/Subsite.vue')
      },
      {
        path: '/company/setting',
        name: 'company-setting',
        component: () => import('../views/company/Setting.vue')
      },
      {
        path: '/company/task',
        name: 'company-task',
        component: () => import('../views/company/Task.vue')
      },
      {
        path: '/user/password',
        name: 'user-password',
        component: () => import('../views/user/Password.vue')
      },
      {
        path: '/user/msg',
        name: 'user-msg',
        component: () => import('../views/user/Message.vue')
      },
      {
        path: '/customer/register',
        name: 'customer-register',
        component: () => import('../views/customer/Register.vue')
      },
      {
        path: '/customer/list',
        name: 'customer-list',
        component: () => import('../views/customer/List.vue'),
        meta: { keepAlive: true }
      },
      {
        path: '/customer/tracking',
        name: 'customer-tracking',
        component: () => import('../views/customer/Tracking.vue')
      },
      {
        path: '/customer/data',
        name: 'customer-data',
        component: () => import('../views/customer/Data.vue'),
        meta: { keepAlive: true }
      },
      {
        path: '/customer/record',
        name: 'customer-record',
        component: () => import('../views/customer/Record.vue')
      },
      {
        path: '/customer/trace',
        name: 'customer-trace',
        component: () => import('../views/customer/Trace.vue'),
        meta: { keepAlive: true }

      },
      {
        path: '/customer/detail',
        name: 'customer-detail',
        component: () => import('../views/customer/Detail.vue')
      },

      {
        path: '/customer/confirm',
        name: 'customer-confirm',
        component: () => import('../views/customer/Confirm.vue')
      },
      {
        path: '/news/slide',
        name: 'news-slide',
        component: () => import('../views/news/Slide.vue')
      },
      {
        path: '/news/list',
        name: 'news-list',
        component: () => import('../views/news/List.vue')
      },
      {
        path: '/news/edit',
        name: 'news-edit',
        component: () => import('../views/news/Edit.vue')
      },
      {
        path: '/news/view',
        name: 'news-view',
        component: () => import('../views/news/View.vue')
      },
      {
        path: '/company/department',
        name: 'company-department',
        component: () => import('../views/company/Department.vue')
      },
      {
        path: '/company/employee',
        name: 'company-employee',
        component: () => import('../views/company/Employee.vue')
      },
      {
        path: '/logs/seller',
        name: 'logs-seller',
        component: () => import('../views/logs/Seller.vue')
      },
      {
        path: '/logs/log',
        name: 'logs-log',
        component: () => import('../views/logs/Log.vue')
      },
      {
        path: '/chart/info',
        name: 'chart-info',
        component: () => import('../views/chart/Info.vue')
      },
      {
        path: '/chart/market',
        name: 'chart-market',
        component: () => import('../views/chart/Market.vue')
      },
      {
        path: '/chart/cmarket',
        name: 'chart-cmarket',
        component: () => import('../views/chart/Cmarket.vue')
      },
      {
        path: '/chart/sell',
        name: 'chart-sell',
        component: () => import('../views/chart/Sell.vue')
      },
      {
        path: '/chart/sellone',
        name: 'chart-sellone',
        component: () => import('../views/chart/Sellone.vue')
      },
      {
        path: '/chart/selltwo',
        name: 'chart-selltwo',
        component: () => import('../views/chart/Selltwo.vue')
      },
      {
        path: '/chart/fall',
        name: 'chart-fall',
        component: () => import('../views/chart/Fall.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  }
  // {
  //   path: '/404',
  //   name: '404',
  //   component: () => import('../views/notFound.vue')
  // },
  // {
  //   path: '*',
  //   redirect: '/404'
  // }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
