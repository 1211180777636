<template>
  <div class="layout">
    <aside>
      <Menu />
    </aside>
    <section class="jue-layout">
      <Header />
      <router-view class="router-view" :key="$route.name"></router-view>
      <Footer />
    </section>
  </div>
</template>
<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import Menu from '@/components/common/Menu.vue'
import { routerBut } from '@/unit/unit.js'
export default {
  components: {
    Header,
    Footer,
    Menu,
  },
  created() {
    //登录后，每次获取用户信息保存在vuex里
    this.$axios.post('/user/detail').then((res) => {
      this.$store.commit('set_user', res.data.data)
    })

    //缓存所有用户列表
    this.$axios.post('/index/users').then((res) => {
      this.$store.commit('set_userList', res.data.data.list)
    })

    //缓存基础数据
    this.$axios.post('/cfgdata/lists').then((res) => {
      var json = {}
      res.data.data.map((item) => {
        json[item.key] = item.list
      })
      this.$store.commit('set_cfgList', json)
    })

    this.$axios.post('/index/cfgdata').then((res) => {
      this.$store.commit('set_cfgData', res.data.data.list)
    })

    this.$axios.post('/index/group').then((res) => {
      let obj = res.data.data.list
      let jsonData = []

      for (var p in obj) {
        jsonData.push({
          label: obj[p],
          value: p,
        })
      }
      this.$store.commit('set_role', jsonData)
    })

    //缓存菜单
    this.$axios.post('/user/menu').then((res) => {
      this.$store.commit('set_menu', res.data.data)
      // console.log(routerBut(res.data.data))
      this.$store.commit('set_auth', routerBut(res.data.data))
    })
  }
}
</script>
<style lang="less" scoped>
.layout {
  display: flex;
  min-height: 100vh;
}
.jue-layout {
  display: flex;
  flex: 1;
  flex-direction: column;
  .router-view {
    flex: 1;
  }
}
</style>
<style lang="less">
// 公共card边距
.main-content {
  margin: 20px 20px 0;
}

//删除按钮红色
.btn-delete {
  color: #f56c6c;
  &:hover {
    color: #f56c6c !important;
    background: rgba(245, 108, 108, 0.1) !important;
  }
  &.no-bg {
    &:hover {
      background: none !important;
    }
  }
}

//page-header设置
.page-header {
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  .page-header-body {
    flex: 1;
    .page-header-title {
      color: rgba(0, 0, 0, 0.85);
      display: flex;
      .name {
        flex: 1;
      }
    }
    .page-header-desc {
      padding: 5px 0 0;
    }
  }
  .head-search {
    min-height: 36px;
    padding: 30px 0;
  }

  .head-tabs {
    display: flex;
    margin-bottom: -20px;
    cursor: pointer;
    .tab {
      padding: 8px;
      margin-right: 32px;
      font-size: 14px;
      &.curr {
        color: #1989fa;
        border-bottom: 2px #1989fa solid;
      }
    }
  }
}

//抽屉头部样式
.jue-drawer-header {
  padding: 20px;
  display: flex;
  align-items: center;
  .jue-drawer-title {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    flex: 1;
  }
  .jue-drawer-btns {
    font-size: 16px;
    cursor: pointer;
  }
}

//card头部样式
.jue-card-header {
  height: 64px;
  padding: 0 20px;
  margin: -20px -20px 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  .jue-card-title {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    flex: 1;
    .totle {
      font-size: 12px;
      padding: 0 10px;
      color: rgba(0, 0, 0, 0.55);
    }
  }
  .jue-card-btns {
    font-size: 16px;
    display: flex;
    align-items: center;
    .btn {
      margin-left: 16px;
    }
    .jue-divider {
      margin: 0 15px;
    }
    .el-icon-download {
      cursor: pointer;
    }
    .el-icon-loading {
      cursor: no-drop;
    }
  }
}

//底部固定提交组
.form-footer {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: #fff;
  border-top: 1px solid #e8e8e8;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.03);
  height: 56px;
  line-height: 56px;
  width: 100%;
}

//表格样式
.table {
  width: calc(100vw - 300px);
  /deep/ .cell {
    // word-break: keep-all;
    // white-space: nowrap;
  }
  /deep/ th {
    // height: 53px;
    background: #fafafa;
    color: rgba(0, 0, 0, 0.85);
    &.el-table-column--selection {
      &:first-child {
        padding-left: 4px;
      }
    }
  }
}

//文字分割竖线
.jue-divider {
  position: relative;
  top: -0.06em;
  display: inline-block;
  width: 1px;
  height: 0.9em;
  margin: 0 8px;
  vertical-align: middle;
  background: #e8e8e8;
}

//帮助提示图标
.icon-help {
  color: #909399;
  cursor: help;
}
</style>