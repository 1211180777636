import Vue from 'vue'
/**权限指令**/
const has = Vue.directive('has', {
  inserted: function (el, binding, vnode) {
    // 获取按钮权限
    // let btnPermissions = localStorage.getItem('x-auth-list')
    // if (btnPermissions.indexOf(binding.value) == -1) {
    //   el.parentNode.removeChild(el);
    // }
  }
});
export { has }