import store from '../store/index.js'
import axios from 'axios'

export function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function compare(property) {
  return function (obj1, obj2) {
    var value1 = obj1[property];
    var value2 = obj2[property];
    return value1 - value2;     // 升序
  }
}

//根据ID返回基础数据信息
export function cfgById(cid) {
  if (cid == '') {
    return ''
  }
  let id = cid
  if (typeof (id) == 'string') {
    id = parseInt(id)
  }

  if (store.state.cfgData[id] == undefined) {
    return ''
  } else {
    return store.state.cfgData[id][0]
  }


}

//根据ID返回职务信息
export function roleByID(id) {
  if (id == '0') {
    return {
      label: '-',
      value: '0'
    }
  }
  if (id == '10000') {
    return {
      label: '-',
      value: '10000'
    }
  }
  return store.state.role.find((value, index, arr) => {
    if (value.value == id) {
      return value
    }
  })
}

export function monthFormat2() {
  // 获取当前日期
  var date = new Date()

  // 获取当前月份
  var nowMonth = date.getMonth() + 1

  // 对月份进行处理，1-9月在前面添加一个“0”
  if (nowMonth >= 1 && nowMonth <= 9) {
    nowMonth = '0' + nowMonth
  }

  // 最后拼接字符串，得到一个格式为(yyyyMM)的日期
  return date.getFullYear().toString() + nowMonth.toString()

}

export function monthFormat() {
  // 获取当前日期
  var date = new Date()


  // 获取当前月份
  var nowMonth = date.getMonth() + 1

  // 对月份进行处理，1-9月在前面添加一个“0”
  if (nowMonth >= 1 && nowMonth <= 9) {
    nowMonth = '0' + nowMonth
  }

  // 最后拼接字符串，得到一个格式为(yyyy-MM-dd)的日期
  return date.getFullYear() + '-' + nowMonth

}

export function dateFormat() {
  // 获取当前日期
  var date = new Date()

  // 获取当前月份
  var nowMonth = date.getMonth() + 1

  // 获取当前是几号
  var strDate = date.getDate()

  // 添加分隔符“-”
  var seperator = '-'

  // 对月份进行处理，1-9月在前面添加一个“0”
  if (nowMonth >= 1 && nowMonth <= 9) {
    nowMonth = '0' + nowMonth
  }

  // 对月份进行处理，1-9号在前面添加一个“0”
  if (strDate >= 0 && strDate <= 9) {
    strDate = '0' + strDate
  }

  // 最后拼接字符串，得到一个格式为(yyyy-MM-dd)的日期
  return date.getFullYear() + seperator + nowMonth + seperator + strDate
}

export function getTimeInterval(time) {
  if (time <= '09:00') {
    return 74
  }

  if (time <= '10:00' && time > '09:00') {
    return 75
  }

  if (time <= '11:00' && time > '10:00') {
    return 77
  }

  if (time <= '12:00' && time > '11:00') {
    return 78
  }

  if (time <= '13:00' && time > '12:00') {
    return 79
  }

  if (time <= '14:00' && time > '13:00') {
    return 80
  }

  if (time <= '15:00' && time > '14:00') {
    return 81
  }

  if (time <= '16:00' && time > '15:00') {
    return 169
  }

  if (time <= '17:00' && time > '16:00') {
    return 170
  }

  if (time > '17:00') {
    return 171
  }

}

export function getCarType() {
  // axios.post('/cfgdata/lists', {
  //   key: 'demandCarType'
  // }).then(res => {
  //   if (res.data.code == 200) {
  //     console.log(res.data.data[0].list)

  //   }
  // })

  // axios.post('/cfgdata/car_attr', {
  //   sys_id: 232
  // }).then(res => {
  //   console.log(res)
  // })

  let arr = []

  let demandCarTypeArr = []
  if(store.state.cfgList.demandCarType){
    demandCarTypeArr = store.state.cfgList.demandCarType.filter(item => item.status == 1)
  }

  let attributeArr = []
  if(store.state.cfgList.attribute){
    attributeArr= store.state.cfgList.attribute.filter(item => item.status == 1)
  }
  

  demandCarTypeArr.map(item => {
    let temp_json = {
      value: item.sys_id,
      label: item.f1
    }
    attributeArr.map(val => {
      if (item.sys_id == parseInt(val.f2)) {
        if (temp_json.children == undefined) {
          temp_json.children = []
        }
        temp_json.children.push({
          value: val.sys_id,
          label: val.f1,
        })
      }
    })

    arr.push(temp_json)
  })


  return arr
}

/**
 * 根据子级类型查找所有匹配的父级类型
 * id: 子级ID
 * data: 匹配数据
 * prop: 匹配的类型,默认用ID匹配
 */
export function getFathersById(id, data, prop = 'id') {
  var arrRes = []
  const rev = (data, nodeId) => {
    for (var i = 0, length = data.length; i < length; i++) {
      const node = data[i]
      if (node[prop] === nodeId) {
        arrRes.unshift(node[prop])
        return true
      } else {
        if (node.children && node.children.length) {
          if (rev(node.children, nodeId)) {
            arrRes.unshift(node[prop])
            return true
          }
        }
      }
    }
    return false
  }
  rev(data, id)
  return arrRes
}

export function handleId(arr, type) {
  if (type === 1) {
    if (arr.length) {
      return arr[arr.length - 1]
    } else return ''
  } else {
    if (arr.length) {
      const newArr = []
      arr.some(item => {
        newArr.push(item[item.length - 1])
      })
      return newArr
    } else return []
  }
}

export function routerBut(routerData) {
  var arr = {}
  // console.log(routerData)
  routerData.forEach(function (v, i) {
    if (v.type === 1) {
      arr[v.route] = {}
    } else {
      arr[v.route] = true
    }

    if (v.children) {
      arr[v.route] = routerBut(v.children)
    }
  })
  return arr
}

export function getAuth(path, str) {
  let pa = path.split('/')
  if (store.state.auth[pa[1]][pa[2]][str] == undefined) {
    return false
  } else {
    return true
  }
}