<template>
  <div class="notice">
    <!-- <router-link :to="{name:'console'}" class="msg-item">
      <div class="item-top">
        <div class="title">新的公告</div>
        <el-tag size="small" type="info">未读</el-tag>
      </div>
      <div class="content">本年度最佳优秀员工</div>
    </router-link>

    <router-link :to="{name:'console'}" class="msg-item">
      <div class="item-top">
        <div class="title">电话回访任务</div>
        <el-tag size="small" type="danger">马上到期</el-tag>
      </div>
      <div class="content">截止到今天 2020年1月20日，您还有4条回访电话没有完成</div>
    </router-link>-->

    <div
      :key="index"
      class="msg-item"
      v-for="(item, index) in noticeData"
      @click="jump(item.sys_id)"
    >
      <div class="item-top">
        <div class="title">{{getTitle(item.notice_type)}}</div>
        <el-tag size="small" type="danger" v-if="item.status == 1">未读</el-tag>
        <el-tag size="small" type="info" v-if="item.status == 2">已读</el-tag>
      </div>
      <div class="content">{{item.title}}</div>
    </div>
    <div class="no-msg" v-if="noticeData.length == 0">没有新的消息</div>
  </div>
</template>
<script>
export default {
  props: {
    searchKey: Object
  },
  data() {
    return {
      noticeData: []
    }
  },
  mounted() {
    this.getNoticeList()
  },
  methods: {
    jump(type){
      this.$axios.post('/notice/detail', {
        sys_id: type
      }).then(res => {
        if(res.data.data.notice_type == 2){
          this.$router.push({name: 'company-task'})
        }


        this.getNoticeList()
      })


    },
    getTitle(notice_type) {
      if (notice_type == 1) {
        return '任务设置'
      }
      if (notice_type == 2) {
        return '检核通知'
      }
      if (notice_type == 3) {
        return '审核通知'
      }
      if (notice_type == 4) {
        return '新闻通知'
      }
    },
    getNoticeList() {
      this.$axios
        .post('/notice/lists', this.searchKey)
        .then(res => {
          if (res.data.code == 200) {
            this.noticeData = res.data.data.list
            // if(res.data.data.list.length == 0){
            //   this.$parent.setUnread()
            // }
          }
        })
    }
  }
}
</script>
<style lang="less" scoped>
.msg-item {
  border-bottom: 1px rgba(0, 0, 0, 0.07) solid;
  margin: 0 -12px;
  padding: 10px 12px;
  cursor: pointer;
  display: block;
  &:last-child {
    border: none;
    margin-bottom: -12px;
  }
  &:first-child {
    margin-top: -12px;
  }
  .item-top {
    display: flex;
    align-items: center;
    .title {
      flex: 1;
      color: rgba(0, 0, 0, 0.65);
    }
  }
  .content {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    padding: 10px 0;
  }
  &:hover {
    background-color: #ecf5ff;
  }
  .more-msg {
    display: block;
    text-align: center;
  }
}

.no-msg {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.45);
}
</style>