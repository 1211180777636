import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/style.css';
// import VCharts from 'v-charts'
import App from './App.vue'
import router from './router'
import store from './store'
import has from './unit/btnPermissions.js'

import moment from 'moment'; //导入模块
moment.locale('zh-cn'); //设置语言 或 moment.lang('zh-cn'); 
Vue.prototype.$moment = moment;//赋值使用
// Vue.use(VCharts)

//配置全局axios
import axios from 'axios'
Vue.prototype.$axios = axios
const url = 'https://api.qk.zhongheinfo.com';
axios.interceptors.request.use(
  config => {
    let token = localStorage.getItem("x-auth-token");
    let site = localStorage.getItem("x-auth-site");
    if (token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers.token = `${token}`;
    }
    if (site) {
      config.headers.site = `${site}`;
    }
    if (config.url.indexOf('aliyuncs.com') === -1) {
      config.url = url + config.url;/*拼接完整请求路径*/
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  });

axios.interceptors.response.use(response => {
  if (response.data.code == '602') {
    //用户token过期
    localStorage.clear()
    router.push({
      name: 'login'
    })
    return false
  }
  return Promise.resolve(response);
},
  err => {
    return Promise.reject(err);
  })


Vue.config.productionTip = false
Vue.use(ElementUI, {
  size: 'small'
});

Vue.directive('title', {
  inserted: function (el) {
    document.title = el.dataset.title
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.getItem("x-auth-token")) {
      next({
        path: "/login",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
