<template>
  <div class="header">
    <span @click="menuCollapse" class="sidebar-btn">
      <i :class="$store.state.isCollapse ? 'el-icon-jue-menu' : 'el-icon-jue-menu_open'"></i>
    </span>
    <div class="flex-1"></div>
    <div class="global-header">
      <span class="header-notice">
        <el-popover
          :offset="-141"
          :visible-arrow="false"
          class="notice-popover"
          placement="bottom"
          trigger="click"
          width="336"
        >
          <div class="notice-list">
            <notice-list :searchKey="searchKey"></notice-list>
            <div class="msg-item">
              <router-link :to="{name:'user-msg'}" class="more-msg">更多消息</router-link>
            </div>
          </div>
          <i class="el-icon-jue-bell" slot="reference">
            <el-badge class="dot" is-dot v-if="$store.state.user.unread_notice"></el-badge>
          </i>
        </el-popover>
      </span>
      <div class="header-user-box">
        <el-dropdown>
          <div class="header-user">
            <el-avatar
              :size="24"
              :src="$store.state.user.avatar"
              class="user-avatar"
              icon="el-icon-user-solid"
            ></el-avatar>
            <span class="user-name">
              {{$store.state.user.realname}}
              <i class="el-icon-jue-expand_more"></i>
            </span>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              @click.native="$router.push({name: 'company-task'})"
              icon="el-icon-jue-playlist_add_check-px"
            >我的任务</el-dropdown-item>
            <el-dropdown-item
              @click.native="$router.push({name: 'company-setting'})"
              icon="el-icon-jue-perm_identity-px"
            >个人设置</el-dropdown-item>
            <el-dropdown-item
              @click.native="logout"
              class="btn-delete"
              divided
              icon="el-icon-jue-power_settings_new-px"
            >退出登陆</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
import NoticeList from '@/components/NoticeList.vue'
export default {
  components: {
    NoticeList
  },
  data() {
    return {
      drawer: false,
      userinfo: {
        realname: ''
      },
      searchKey: {
        page: 1,
        count: 5,
        status: 1
      }
    }
  },
  methods: {
    menuCollapse() {
      this.$store.commit('update_isCollapse')
    },
    logout() {
      this.$axios.post('/user/logout').then(res => {
        if (res.data.code == 200) {
          localStorage.removeItem('x-auth-token')
          localStorage.removeItem('x-auth-list')
          this.$store.commit('set_user', {})
          this.$router.push({
            name: 'login'
          })
        }
      })
    },

    setUnread(){
      this.$store.commit('set_unread')
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  display: flex;
  height: 64px;
  align-items: center;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  position: relative;
}
.sidebar-btn {
  display: flex;
  padding: 0 24px;
  height: 100%;
  align-items: center;
  cursor: pointer;
  i {
    font-size: 20px;
  }
}
.flex-1 {
  flex: 1;
}
.global-header {
  display: flex;
  align-items: center;
  height: 100%;
  .header-notice {
    display: flex;
    align-items: center;
    padding: 0 12px;
    height: 100%;
    font-size: 20px;
    cursor: pointer;
    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
    .dot {
      top: -4px;
      left: -5px;
    }
  }
  .header-user-box {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }
  .header-user {
    display: flex;
    align-items: center;
    padding: 0 12px;
    .user-name,
    .user-avatar {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
    }
    .user-avatar {
      margin-right: 8px;
    }
  }
}

.msg-item {
  .more-msg {
    display: block;
    text-align: center;
    margin-top: 20px;
  }
}
</style>