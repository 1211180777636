import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //菜单是否收缩
    isCollapse: false,
    //横向table宽度 
    tableWidth: 'calc(100vw - 292px)',
    user: {}, //用户详细
    userList: {},
    cfgList: {}, //基础数据
    cfgData: {},
    role: [],
    //菜单
    menu: {},
    auth:{}
  },
  mutations: {
    update_isCollapse(state){
      state.isCollapse = !state.isCollapse
      if(state.isCollapse){
        state.tableWidth = 'calc(100vw - 122px)'
      }else{
        state.tableWidth = 'calc(100vw - 292px)'
      }
    },

    set_user(state,val){
      state.user = val
    },

    set_userList(state, json){
      state.userList = json
    },

    set_cfgList(state, json){
      state.cfgList = json
    },
    set_cfgData(state, json){
      state.cfgData = json
    },
    set_unread(state){
      state.user.unread_notice = 0
    },
    set_role(state, json){
      state.role = json
    },
    set_menu(state, json){
      state.menu = json
    },
    set_auth(state, json){
      state.auth = json
    }

  },
  actions: {
  },
  modules: {
  }
})
